import { useLayoutEffect, useState } from "preact/compat";
import { breakpoints, type FormFactor } from "../config/breakpoints";

const useFormFactor = () => {
  const [formFactor, setFormFactor] = useState<FormFactor | undefined>(
    undefined,
  );

  const find = () => {
    const isLandScope = window.innerWidth >= window.innerHeight;
    if (window.innerWidth < breakpoints.tablet) {
      setFormFactor("mobile");
    } else if (window.innerWidth < breakpoints.desktop && !isLandScope) {
      setFormFactor("tablet");
    } else {
      setFormFactor("desktop");
    }
  };

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", find);
      find();
      return function () {
        window.removeEventListener("resize", find);
      };
    }
  }, []);

  return { formFactor };
};

export default useFormFactor;
